// src/providers/AccountProvider.js
import React from "react";
// Set Up The Initial Context
export const UserContext = React.createContext();
// Create an exportable consumer that can be injected into components
export const UserConsumer = UserContext.Consumer;
// Create the provider using a traditional React.Component class

class UserContextProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: ""
    };
    this.setEmail = this.setEmail.bind(this);
  }

  setEmail(email) {
    this.setState({ email: email });
  }

  render() {
    return (
      // value prop is where we define what values
      // that are accessible to consumer components
      <UserContext.Provider
        value={{
          email: this.state.email,
          setEmail: this.setEmail
        }}
      >
        {this.props.children}
      </UserContext.Provider>
    );
  }
}
export default UserContextProvider;
