import React from "react";
import axios from "axios";

import { CopyToClipboard } from "react-copy-to-clipboard";
import { Button, Table, message } from "antd";
import { useState } from "react";

import { getToken } from "../Anon.js";
import "../App.css";

function loadUsers(appID, token, callback) {
  const Url = process.env.REACT_APP_API_URL + "users/downloadUserData";

  console.log("loadUsers, token: " + token);

  const options = {
    method: "POST",
    mode: "cors",
    headers: {
      Authorization: "Bearer " + token,
      "x-api-key": process.env.REACT_APP_API_KEY_VALUE,
      "Content-Type": "application/json"
    },
    url: Url,
    data: {
      appID: appID
    }
  };

  axios(options)
    .then(function(value) {
      callback(value);
    })
    .catch(function(error) {
      console.log(error);
    })
    .finally(function() {});
}

const columns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id"
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name"
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email"
  },
  {
    title: "Email verified",
    dataIndex: "ver",
    key: "ver"
  },
  {
    title: "Creation Date",
    dataIndex: "date",
    key: "date"
  }
];

function UserList(props) {
  const urlParams = new URLSearchParams(window.location.search);
  const appID = urlParams.get("id");
  const [data, setData] = useState([]);
  const [csvdata, setCsvdata] = useState("");

  const [loading, setLoading] = useState(false);

  const listener = function processUsers(users) {
    console.log("Users:" + JSON.stringify(users));

    const data = [];
    var csvdata = "ID,Name,Email,Verified,Created\n";
    users = users["data"];

    for (var index = 0; index < users.length; index++) {
      var user = users[index];
      var name = user["name"];
      var email = user["email"];
      var id = user["sub"];
      var ver = user["email_verified"];
      var date = user["UserCreateDate"];

      data.push({
        key: id,
        id: id,
        name: name,
        email: email,
        ver: ver,
        date: date
      });

      var row = id + "," + name + "," + email + "," + ver + "," + date + "\n";

      csvdata = csvdata.concat(row);
    }

    setLoading(false);
    setData(data);
    console.log(csvdata);
    setCsvdata(csvdata);
  };

  function download() {
    setLoading(true);

    getToken().then(
      function(token) {
        loadUsers(appID, token, listener);
      },
      function(error) {
        console.error("Failed!", error);
      }
    );
  }

  return (
    <div style={{ margin: 18 }}>
      <p>
        We do not recommend looking at the personally identifiable information
        for your users. However we understand that it is necessaary under some
        circumstances such as email marketing. When you click on the
        confirmation button, we record that you accessed this data and a record
        of that will appear in your users future Subject Access Requests.
      </p>
      <Button style={{ margin: 8 }} type="danger" onClick={download}>
        Confirm you want to view personally identifiable information.
      </Button>

      <CopyToClipboard
        text={csvdata}
        onCopy={() => {
          message.success("Copied user list to clipboard");
        }}
      >
        <Button>Copy</Button>
      </CopyToClipboard>

      <div>
        <Table
          loading={loading}
          columns={columns}
          dataSource={data}
          size="small"
        />
      </div>
    </div>
  );
}
export default UserList;
