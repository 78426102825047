import React from "react";
import axios from "axios";
import { UserConsumer } from "../Contexts";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  Divider,
  Spin,
  Collapse,
  Row,
  Col,
  Button,
  Modal,
  Form,
  Steps,
  Tabs,
  message,
  List,
  Radio,
  Tag,
  Input
} from "antd";
import {
  SettingTwoTone,
  DeleteTwoTone,
  AmazonOutlined,
  EditTwoTone
} from "@ant-design/icons";

import { EventEmitter, getToken } from "../Anon.js";
import "../App.css";
import { Light as SyntaxHighlighter } from "react-syntax-highlighter";
import js from "react-syntax-highlighter/dist/esm/languages/hljs/javascript";
import agate from "react-syntax-highlighter/dist/esm/styles/hljs/agate";

import { useState, useEffect } from "react";
const { Step } = Steps;
const { Panel } = Collapse;
const { TabPane } = Tabs;

SyntaxHighlighter.registerLanguage("javascript", js);

function sendTestToHook(appID, token) {
  const Url = process.env.REACT_APP_API_URL + "users/sendWebhookTest";

  const data = {
    appID: appID
  };

  post(token, Url, data, appID);
}

function setVerificationMessage(appID, token, subject, content) {
  const Url = process.env.REACT_APP_API_URL + "users/setVerificationMessage";

  const data = {
    appID: appID,
    subject: subject,
    content: content
  };

  post(token, Url, data, appID);
}

function setAppDetails(appID, token, details) {
  const Url = process.env.REACT_APP_API_URL + "users/setAppDetails";

  const data = {
    appID: appID,
    details: details
  };

  post(token, Url, data, appID);
}

function post(token, url, data, appID) {
  const options = {
    method: "POST",
    mode: "cors",
    headers: {
      Authorization: "Bearer " + token,
      "x-api-key": process.env.REACT_APP_API_KEY_VALUE,
      "Content-Type": "application/json"
    },
    url: url,
    data: data
  };

  axios(options)
    .then(function(value) {
      loadAppDetails(appID, token);
      //console.log("AppDetails:" + JSON.stringify(value));
    })
    .catch(function(error) {
      console.log(error);
    })
    .finally(function() {});
}

function loadAppDetails(appID, token) {
  console.log("loadAppDetails, id: " + appID);
  const Url = process.env.REACT_APP_API_URL + "users/getAppDetails";

  console.log("loadAppDetails, token: " + token);

  const options = {
    method: "POST",
    mode: "cors",
    headers: {
      Authorization: "Bearer " + token,
      "x-api-key": process.env.REACT_APP_API_KEY_VALUE,
      "Content-Type": "application/json"
    },
    url: Url,
    data: {
      appID: appID
    }
  };

  axios(options)
    .then(function(value) {
      console.log("AppDetails:" + JSON.stringify(value));
      EventEmitter.dispatch("appDetailsArrived", value.data);
    })
    .catch(function(error) {
      console.log(error);
    })
    .finally(function() {});
}

function deleteApp(appID, token) {
  console.log(
    "deleteApp, token: " + JSON.stringify(token) + " appID: " + appID
  );

  const Url = process.env.REACT_APP_API_URL + "users/deleteApp";

  const options = {
    method: "POST",
    mode: "cors",
    headers: {
      Authorization: "Bearer " + token,
      "x-api-key": process.env.REACT_APP_API_KEY_VALUE,
      "Content-Type": "application/json"
    },
    url: Url,
    data: {
      appID: appID
    }
  };

  axios(options)
    .then(function(value) {
      console.log("AppDetails, id=" + JSON.stringify(value));
    })
    .catch(function(error) {
      console.log(error);
    })
    .finally(function() {});
}

function AppDetails(props) {
  const urlParams = new URLSearchParams(window.location.search);
  const appID = urlParams.get("id");
  console.log("AppDetails, id=" + appID);

  getToken().then(
    function(token) {
      loadAppDetails(appID, token);
    },
    function(error) {
      console.error("Failed!", error);
    }
  );

  useEffect(() => {}, [props]);
  /*
  useEffect(() => {
    if (token !== null) {
      console.log(token);
    } else {
      console.log("token is null");
    }
  }, [token]);
*/
  return (
    <div style={{ margin: 18 }}>
      <UserConsumer>
        {({ token }) => {
          //loadAppDetails(appID, { token });
          return (
            <div>
              <AppDetails2 {...props} appid={appID} />
            </div>
          );
        }}
      </UserConsumer>
    </div>
  );
}
const codeString0 = "\t>    npm install jevul";
const codeString1 =
  "//near the top of the file, import our code\n" +
  'import { Jevul, withJevulAuthenticator } from "jevul";\n' +
  "//your other imports etc. here\n\n" +
  "//Somewhere you need to initialise Jevul\n" +
  "//To make things easy we put your actual codes in the line\n" +
  "//so you can cut and paste it\n" +
  'var jevul = Jevul.init("';
const codeString1a =
  '",\n\t\t"' +
  process.env.REACT_APP_API_GATEWAY_URL +
  '");\n\n' +
  "//your code here\n\n" +
  "//at the bottom of the file wrap your react app with an authenticator\n" +
  'export default withJevulAuthenticator(App, "';

const codeString2 =
  "jevul.getUserDetails( data => {\n" +
  "\t//add your code here that does stuff e.g.\n" +
  "\t//console.log(JSON.stringify(data));\n" +
  "});";

const codeString3 =
  "//store, receive, delete arbitrary data against a user by a key\n" +
  "jevul.setUserData(yourKey, /* string, required */ \n" +
  "\t\tyourValue, /* anything, required; will resolve to json */ \n" +
  "\t\tresponse => {} ); /* optional callback */ \n" +
  "\n" +
  "jevul.getUserData(yourKey, /* string, required; returns empty map if does not exist */ \n" +
  "\t\tdefaultResponse, /* anything, optional; returned if no value with given key */\n" +
  "\t\tdata => {/*add your code here*/}); /* optional callback, but pointless without*/\n" +
  "\n" +
  "jevul.deleteUserData(yourKey, /* string, required */ \n" +
  "\t\tresponse => {} ); /* optional callback */ \n";

const RenderBasicSettings = params => (
  <div>
    <List itemLayout="horizontal">
      <List.Item>
        <div className="settingslist">
          <TextRow
            text={
              "Install the Jevul module to your development environment using npm:"
            }
          />
          <Row>
            <Col>
              <pre>
                <code>{codeString0}</code>
              </pre>
            </Col>
          </Row>
          <TextRow text={"Add a few lines of code to your app:"} />
          <CodeView
            text={
              codeString1 +
              params["appID"] +
              '",\n\t\t"' +
              params["poolID"] +
              '",\n\t\t"' +
              params["clientID"] +
              codeString1a +
              params["appName"] +
              '");\n'
            }
            text2={params.loginURL}
          />
        </div>
      </List.Item>
    </List>
  </div>
);

const MachineSettings = params => (
  <div>
    <br />
    <p>
      Use your machine client key ({params["clientID2"]}) and secret (
      {params["secret"]}) to generate a base64 encoded HTTP Basic Authorization
      header. For example, using openssl:
    </p>
    <code>
      echo -n '{params["clientID2"]}:{params["secret"]}' | openssl base64 -A
    </code>
    <br />
    <br />
    <p>Use this to request an access token:</p>
    <code>
      curl -X POST <br />
      https://{params["domain"]}.auth.{params["region"]}
      .amazoncognito.com/oauth2/token
      <br />
      -H 'Authorization: Basic INSERT_ENCODED_HEADER_HERE'
      <br />
      -H 'content-type: application/x-www-form-urlencoded' <br />
      -d 'grant_type=client_credentials&scope=machine1%2FadminQuery'
    </code>
    <br />
    <br />
    <p>Use the token returned to you to query Jevul:</p>
    <code>
      curl -X POST <br />
      {params["gateway"]}machines/getAllUserDetails
      <br />
      -H 'Authorization: Bearer INSERT_ACCESS_TOKEN_HERE' <br />
      -H 'Content-Type: application/json' <br />
      -H 'x-api-key:{params["appID"]}'
      <br />
    </code>
  </div>
);

function PrivacyStatementSettings(params) {
  console.log("PrivacyStatementSettings:" + JSON.stringify(params));

  const [defaultOption, setDefaultOption] = useState(1);
  const [disableSave, setDisableSave] = useState(true);
  const [privacyURL, setPrivacyURL] = useState(params.bespokeURL);

  // Update state for privacy when bespokeURL changes
  useEffect(() => {
    if (params.bespokeURL.length > 0) {
      setDefaultOption(2);
    }

    setPrivacyURL(params.bespokeURL);
  }, [params.bespokeURL]);

  function toggleRadio(e) {
    setDefaultOption(e.target.value);

    if (e.target.value === 2) {
      setDisableSave(!isValidURL(privacyURL));
    } else {
      setDisableSave(false);
    }
  }

  function urlChanged(e) {
    setPrivacyURL(e.target.value);

    console.log("new url:" + privacyURL);

    if (defaultOption === 2) {
      setDisableSave(!isValidURL(privacyURL));
    }
  }

  function savePrivacyURL() {
    getToken().then(
      function(token) {
        console.log("Save privacy statement url: " + privacyURL);

        if (defaultOption === 1) {
          setAppDetails(params.appID, token, {"PrivacyURL": ""});
        } else if (defaultOption === 2) {
          setAppDetails(params.appID, token, {"PrivacyURL": privacyURL});
        }
        setDisableSave(true);
      },
      function(error) {
        console.error("Failed!", error);
      }
    );
  }

  const radioStyle = {
    display: "block"
  };

  return (
    <div style={{ marginTop: 15 }}>
      <Radio.Group onChange={toggleRadio} value={defaultOption}>
        <Row>
          <Col span={24}>
            <Radio style={radioStyle} value={1}>
              <CopyToClipboard
                text={params.url}
                onCopy={() => {
                  message.success("copied to clipboard");
                }}
              >
                <pre>{params.url}</pre>
              </CopyToClipboard>
            </Radio>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Radio style={radioStyle} value={2}>
              <Input
                placeholder="https://your_url.com"
                value={privacyURL}
                onChange={urlChanged}
              />
            </Radio>
          </Col>
        </Row>
      </Radio.Group>
      <Row>
        <Col span={1}>
          <Button
            type="primary"
            disabled={disableSave}
            onClick={savePrivacyURL}
            style={{ marginRight: 15, marginTop: 15 }}
          >
            Save
          </Button>
        </Col>
      </Row>
    </div>
  );
}

function isValidURL(str) {
  if (
    (str.toLowerCase().startsWith("http://") ||
      str.toLowerCase().startsWith("https://")) === false
  )
    return false;

  if (str.includes(" ")) return false;

  var res = str.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g
  );
  return res !== null;
}

function WebHookSettings(params) {
  console.log("hook=" + params.url);
  const [disableSave, setDisableSave] = useState(true);
  const [disableTest, setDisableTest] = useState(false);
  const [webhook, setWebhook] = useState(params.url);

  function testWebhook(event) {
    console.log("Test webhook: " + webhook);

    getToken().then(
      function(token) {
        console.log("Sent webhook: " + webhook);
        sendTestToHook(params.appID, token);
      },
      function(error) {
        console.error("Failed!", error);
      }
    );
  }

  function saveWebhook(event) {
    getToken().then(
      function(token) {
        console.log("Save webhook: " + webhook);
        setAppDetails(params.appID, token, {"Webhook": webhook});
        setDisableSave(true);
        setDisableTest(false);
      },
      function(error) {
        console.error("Failed!", error);
      }
    );
  }

  function valueChanged(e) {
    console.log("changed webhook: " + JSON.stringify(e.target.value));
    setWebhook(e.target.value);

    if (isValidURL(e.target.value)) {
      setDisableSave(false);
      setDisableTest(true);
    } else {
      setDisableSave(true);
      setDisableTest(true);
    }
  }

  return (
    <div>
      <div style={{ marginBottom: 15 }}>
        <Input
          placeholder="your_url.com"
          value={webhook}
          onChange={valueChanged}
        />
      </div>
      <Button
        type="primary"
        disabled={disableSave}
        onClick={saveWebhook}
        style={{ marginRight: 15 }}
      >
        Save
      </Button>

      <Button type="primary" onClick={testWebhook} disabled={disableTest}>
        Test
      </Button>
      <br />
      <br />
      <br />
      <p>
        You then need to extract the "id" key from the body of the notification
        and make a machine to machine API call, described above, using the
        getNotification method, to recover the contents of the notification. So
        for an ID with the value '02ad673c-15b9-4c28-835f-97c8eabc9596':
      </p>
      <code>
        curl -X POST <br />
        {params.gateway}machines/getNotification
        <br />
        -H 'Authorization: Bearer INSERT_ACCESS_TOKEN_HERE' <br />
        -H 'Content-Type: application/json' <br />
        -H 'x-api-key:{params["appID"]}' <br />
        -H 'jevul-notification-id: 02ad673c-15b9-4c28-835f-97c8eabc9596'
        <br />
      </code>
      <br />
      <p>
        Each recovered notification includes a timestamp, a security group
        source (ISS), an agent identity (SUB) and a type. Types of notification
        and type of owner map in the following ways:
      </p>
      <ul>
        <li>type=ACCOUNT_DELETED, agent=user (ie. your user)</li>
        <li>type=DATA_DOWNLOADED, agent=owner (ie. you)</li>
        <li>type=ILLEGAL_ACCESS, agent=someone who is not you</li>

        <li>type=KEY_CREATED, agent=admin</li>
        <li>type=KEY_DELETED, agent=admin</li>
        <li>type=USER_ADDED_TO_GROUP, agent=admin</li>
        <li>type=USER_REMOVED_FROM_GROUP, agent=admin</li>
        <li>type=USER_AUTHORIZED, agent=admin</li>
        <li>type=USER_DEAUTHORIZED, agent=admin</li>

        <li>type=SECURITY_GROUP_DELETED, agent=owner</li>
        <li>type=EMAIL_CONFIRMED, agent=user</li>
        <li>type=TEST_NOTIFICATION, agent=owner</li>
      </ul>
    </div>
  );
}

const DetailsSettings = params => (
  <div>
    <List itemLayout="horizontal">
      <List.Item>
        <div className="settingslist">
          <CodeView text={codeString2} />
        </div>
      </List.Item>
    </List>
  </div>
);

const ReadWriteSettings = params => (
  <div>
    <List itemLayout="horizontal">
      <List.Item>
        <div className="settingslist">
          <CodeView text={codeString3} />
        </div>
      </List.Item>
    </List>
  </div>
);

function TextRow(props) {
  return (
    <Row>
      <Col>
        <p>{props.text}</p>
      </Col>
    </Row>
  );
}

function CodeView(props) {
  return (
    <div>
      <Tabs defaultActiveKey="1">
        <TabPane tab="React" key="1">
          <div style={{ width: "200%" }}>
            <SyntaxHighlighter
              showLineNumbers="true"
              language="javascript"
              style={agate}
            >
              {props.text}
            </SyntaxHighlighter>
          </div>
        </TabPane>
        <TabPane tab="Javascript" key="2">
          {props.text2}
        </TabPane>
      </Tabs>
    </div>
  );
}

function AppDetails2(props) {
  const appID = props.appid;

  console.log("AppDetails2, app id=" + appID);
  const [current, setCurrent] = useState(0);

  const [state, setState] = useState({
    appName: "",
    callbackURL: "",
    entity: "",
    clientID: "",
    poolID: "",
    webhook: "",
    loginURL: "",
    privacyURL: "",
    loading: true,
    bespokePrivacyURL: ""
  });

  const [disableDelete, setDisableDelete] = useState(false);
  const [showDeleteAppModal, setShowDeleteAppModal] = useState(false);

  function showDeleteConfirmModal() {
    setShowDeleteAppModal(true);
  }

  function hideDeleteConfirmModal() {
    setShowDeleteAppModal(false);
  }

  function processDelete() {
    //console.log("con2: " + this.props.appid);
    getToken().then(
      function(token) {
        deleteApp(appID, token);
      },
      function(error) {
        console.error("Failed!", error);
      }
    );
    setShowDeleteAppModal(false);
    setDisableDelete(true);
  }

  useEffect(() => {
    const setDetails = details => {
      console.log("AppDetails, got details:" + JSON.stringify(details));

      try {
        var hook = details["webhook"];
        details["webhook"] = hook;
      } catch (error) {
        console.log("No known webhook");
      }
      try {
        var url = details["privacyURL"];

        if (url === undefined) {
          url = "";
        }
        details["bespokePrivacyURL"] = url;
      } catch (error) {
        console.log("No known privacyURL");
      }

      details["loginURL"] =
        "https://" +
        details["callbackUrl"] +
        ".auth." +
        process.env.REACT_APP_AUTH_REGION +
        ".amazoncognito.com" +
        "/signup?response_type=token&client_id=" +
        details["clientID"] +
        "&redirect_uri=" +
        details["callbackUrl"];

      details["privacyURL"] =
        process.env.REACT_APP_API_URL +
        "users/getPrivacyStatement?entity=" +
        encodeURIComponent(details["entity"]) +
        "&email=";

      details["privacyHome"] =
        process.env.REACT_APP_PRIVACY_HOME +
        "/index.html?pool=" +
        details["poolID"] +
        "&key=" +
        appID +
        "&client=" +
        details["clientID"] +
        "&entity=" +
        encodeURIComponent(details["entity"]);

      details["loading"] = false;

      setState(details);
    };

    EventEmitter.subscribe("appDetailsArrived", setDetails);

    return () => {
      EventEmitter.unsubscribe("appDetailsArrived", setDetails);
    };
  }, [appID]);

  function onChange(e) {
    setCurrent(e);
  }
  var title = "Go live with " + state.appName;

  var params = {
    poolID: state.poolID,
    clientID: state.clientID,
    clientID2: state.clientID2,
    secret: state.secret,
    appName: state.appName,
    appID: appID,
    loginURL: state.loginURL,
    region: process.env.REACT_APP_AUTH_REGION,
    domain: state.domainName,
    gateway: process.env.REACT_APP_API_GATEWAY_URL
  };

  return (
    <div className="UserDetails">
      <UserConsumer>
        {({ email }) => {
          console.log("EMAIL=" + JSON.stringify({ email }));

          return (
            <div>
              <Divider orientation="left">
                <SettingTwoTone
                  twoToneColor="#52c41a"
                  style={{ marginRight: 15 }}
                />
                {state.appName}
              </Divider>

              <Collapse defaultActiveKey={["1"]}>
                <Panel header={title} key="1">
                  <Spin spinning={state.loading}>
                    <div>
                      <Steps
                        direction="vertical"
                        size="small"
                        onChange={onChange}
                        current={current}
                      >
                        <Step
                          title="Add this link for signup/login to your website"
                          description={<RenderBasicSettings {...params} />}
                        ></Step>
                        <Step
                          title="A privacy statement is compulsory. Use the default statement we generate or provide a link to your own."
                          description={
                            <PrivacyStatementSettings
                              appID={appID}
                              url={state.privacyURL + email}
                              bespokeURL={state.bespokePrivacyURL}
                            />
                          }
                        />
                        <Step title="Wait for your first user to signup and login" />
                      </Steps>
                    </div>
                  </Spin>
                </Panel>
                <Panel header="Achieve more" key="2">
                  <h1>Achieve more with {state.appName}</h1>
                  <Steps direction="vertical" size="small">
                    <Step
                      title="Get the basic information about your user:"
                      description={<DetailsSettings />}
                    ></Step>
                    <Step
                      title="Write and read custom data about your user:"
                      description={<ReadWriteSettings />}
                    ></Step>
                    <Step
                      title="Add a link to your website for Subject Access Requests"
                      description={
                        <CopyToClipboard
                          text={state.privacyHome}
                          onCopy={() => {
                            message.success("copied to clipboard");
                          }}
                        >
                          <pre>{state.privacyHome}</pre>
                        </CopyToClipboard>
                      }
                    />
                    <Step
                      title="Use the machine to machine API to load a complete list of users, including personally identifiable information. 
                      WE DO NOT RECOMMEND THIS. All such downloads are timestamped, watermarked and recorded. However, there are situations such as marketing campaings that we do not 
                      currently support."
                      description={<MachineSettings {...params} />}
                    ></Step>

                    <Step
                      title="Use the machine to machine API and a webhook to get real time notifications about user sign ups, logins and account changes. Save your hook url here:"
                      description={
                        <WebHookSettings
                          appID={appID}
                          url={state.webhook}
                          gateway={process.env.REACT_APP_API_GATEWAY_URL}
                        />
                      }
                    ></Step>
                  </Steps>
                </Panel>
                <Panel header="Details" key="3">
                  <p>
                    <Tag color="geekblue">Email verification text</Tag>
                    <EmailDetailsButton
                      title="Edit email verification message"
                      appID={appID}
                      default={state.appName}
                      verificationMessage={state.verificationMessage}
                    />
                  </p>
                  <p>
                    <Tag color="magenta">Security group name</Tag>
                    {state.appName}
                    <AppDetailsButton
                      appKey="PoolName"
                      title="Edit security group name"
                      appID={appID}
                      default={state.appName}
                    />
                  </p>
                  <p>
                    <Tag color="magenta">Company name or url</Tag>
                    {state.entity}
                    <AppDetailsButton
                      appKey="Entity"
                      title="Edit company name or url"
                      appID={appID}
                      default={state.entity}
                    />
                  </p>
                  <p>
                    <Tag color="purple">Landing page after sign in</Tag>
                    {state.callbackUrl}
                  </p>
                  <p>
                    <Tag color="orange">Web client key</Tag>
                    {state.clientID}
                  </p>
                  <p>
                    <Tag color="blue">Link for customers to login</Tag>
                    {state.loginURL}
                  </p>
                  <p>
                    <Tag color="red">Link to generated privacy policy</Tag>
                    {state.privacyURL + email}
                  </p>
                  <p>
                    <Tag color="red">
                      Link to privacy app, for your users to manage their
                      permissions
                    </Tag>
                    {state.privacyHome}
                  </p>
                  <p>
                    <Tag color="green">ISS</Tag>
                    {state.poolID}
                  </p>
                </Panel>
              </Collapse>

              <Row
                style={{ margin: 8 }}
                type="flex"
                justify="start"
                align="top"
              >
                <Col>
                  <Button
                    style={{ margin: 8 }}
                    type="secondary"
                    onClick={showDeleteConfirmModal}
                    disabled={disableDelete}
                  >
                    <DeleteTwoTone />
                    Delete this security group
                  </Button>
                  <Modal
                    title="Confirm Delete"
                    visible={showDeleteAppModal}
                    onOk={processDelete}
                    onCancel={hideDeleteConfirmModal}
                  >
                    <p>Are you sure you want to delete this security group?</p>
                  </Modal>
                  <Button
                    onClick={e => {
                      e.preventDefault();
                      props.history.push(`/userList?id=${appID}`);
                    }}
                  >
                    Access personally identifiable information
                  </Button>
                  <Button type="secondary" disabled style={{ margin: 8 }}>
                    <AmazonOutlined />
                    Take ownership on AWS
                  </Button>
                </Col>
              </Row>
            </div>
          );
        }}
      </UserConsumer>
    </div>
  );
}

function EmailDetailsButton(props) {

  const [showEditModal, setShowEditModal] = useState(false);

  function doShowEditModal() {
    setShowEditModal(true);
  }

  function doHideEditModal() {
    setShowEditModal(false);
  }

  function edit(values) {
    console.log("Edit entity 1: " + JSON.stringify(values));
 
    getToken().then(
      function(token) {
        console.log("Edit " + props.appKey);
        setVerificationMessage(props.appID, token, values.subject, values.content);
        setShowEditModal(false);
      },
      function(error) {
        console.error("Failed!", error);
      }
    );
  }
  const [form] = Form.useForm();
  const warning = "You must include the wild card {####} to represent the confirmation code within the content.";
  return (
    <span>
      <Button
        size="small"
        type="link"
        icon={<EditTwoTone twoToneColor="#52c41a" />}
        onClick={doShowEditModal}
      ></Button>
      <Modal
        title={props.title}
        visible={showEditModal}
        onOk={() => {
          form
            .validateFields()
            .then(values => {
              edit(values);
            })
            .catch(info => {
              console.log("Validate Failed:", info);
            });
        }}
        onCancel={() => {
          form.resetFields();
          doHideEditModal();
        }}
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={{ name: props.default }}
        >
          <Form.Item
            label="Subject"
            name="subject"
            rules={[
              {
                required: true,
                min: 3,
                message: "Content must be greater than 3 characters long"
              }
            ]}
          >
            <Input defaultValue={props.verificationMessage.subject}/>
          </Form.Item>
          <Form.Item
            label="Content"
            name="content"
            rules={[
              {
                required: true,
                pattern: /{####}/,
                message: "Content must contain the wild card {####}"
              }
            ]}
          >
            <Input.TextArea defaultValue={props.verificationMessage.content} />
          </Form.Item>
        </Form>
        <p>{warning}</p>
      </Modal>
    </span>
  );
}

function AppDetailsButton(props) {

  const [showEditModal, setShowEditModal] = useState(false);

  function doShowEditModal() {
    setShowEditModal(true);
  }

  function doHideEditModal() {
    setShowEditModal(false);
  }

  function edit(values) {
    console.log("Edit entity 1: " + JSON.stringify(values.name));

    getToken().then(
      function(token) {
        var details = {};
        details[props.appKey] = values.name;
        console.log("Edit " + JSON.stringify(details));

        setAppDetails(props.appID, token, details);
        setShowEditModal(false);
      },
      function(error) {
        console.error("Failed!", error);
      }
    );
  }
  const [form] = Form.useForm();

  return (
    <span>
      <Button
        size="small"
        type="link"
        icon={<EditTwoTone twoToneColor="#52c41a" />}
        onClick={doShowEditModal}
      ></Button>
      <Modal
        title={props.title}
        visible={showEditModal}
        onOk={() => {
          form
            .validateFields()
            .then(values => {
              edit(values);
            })
            .catch(info => {
              console.log("Validate Failed:", info);
            });
        }}
        onCancel={() => {
          form.resetFields();
          doHideEditModal();
        }}
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={{ name: props.default }}
        >
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                min: 3,
                message: "Names must be greater than 3 characters long"
              }
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </span>
  );
}

export default AppDetails;
