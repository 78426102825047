//import { withAuthenticator } from "@aws-amplify/ui-react";
//import { Auth } from "aws-amplify";

import {
  AmplifyAuthenticator,
  AmplifySignIn,
  AmplifySignUp
} from "@aws-amplify/ui-react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { onAuthUIStateChange, AuthState } from "@aws-amplify/ui-components";

import { HomeOutlined, LogoutOutlined } from "@ant-design/icons";

import { Layout, Menu } from "antd";
import "antd/dist/antd.css";
import "./App.css";
import AppDetails from "./components/AppDetails";
import Overview from "./components/Overview";
import UserList from "./components/UserList";
import { Auth, appendToCognitoUserAgent } from "@aws-amplify/auth";

import React from "react";

import UserContextProvider from "./Contexts";

const { Header, Content, Footer, Sider } = Layout;

function handleClick(e) {
  console.log("click", e.key);
  switch (e.key) {
    case "1":
      //signout();
      break;
    case "4":
      Auth.signOut()
        .then(data => console.log(data))
        .catch(err => console.log(err));
      break;
    default:
  }
}

function JevulAuthenticator(props) {
  console.log("ggg:" + props.organization);
  const [signedIn, setSignedIn] = React.useState(false);

  React.useEffect(() => {
    appendToCognitoUserAgent("withAuthenticator");
    async function check() {
      try {
        const user = await Auth.currentAuthenticatedUser();
        if (user) setSignedIn(true);
      } catch (err) {
        console.debug(err);
      }
    }
    check();
    return onAuthUIStateChange(authState => {
      if (authState === AuthState.SignedIn) {
        setSignedIn(true);
      } else if (authState === AuthState.SignedOut) {
        setSignedIn(false);
      }
    });
  }, []);

  if (!signedIn) {
    return (
      <div style={{ marginLeft: "50px", marginTop: "50px" }}>
        <JevulAuthenticator2 organization={props.organization}>
          {props.children}
        </JevulAuthenticator2>
      </div>
    );
  }
  return (
    <div>
      <JevulAuthenticator2 organization={props.organization}>
        {props.children}
      </JevulAuthenticator2>
    </div>
  );
}

function JevulAuthenticator2(props) {
  console.log("ggh:" + props.organization);

  var signUpText = "Sign up";
  var signInText = "Sign in";

  if (props.organization !== undefined) {
    signInText = "Sign in to " + props.organization;
    signUpText = "Sign up for " + props.organization;
  }

  return (
    <AmplifyAuthenticator
      usernameAlias="email"
      initialAuthState={AuthState.SignUp}
    >
      <AmplifySignIn headerText={signInText} slot="sign-in" />

      <AmplifySignUp
        usernameAlias="email"
        headerText={signUpText}
        formFields={[
          {
            type: "name",
            label: "Name",
            placeholder: "e.g. Winston Churchill",
            required: true,
            handleInputChange: (event, cb) => {
              console.log("custom name field");
              cb(event);
            }
          },
          {
            type: "email",
            label: "Email Address",
            placeholder: "e.g. winstonchurchill@gmail.com",
            required: true,
            handleInputChange: (event, cb) => {
              console.log("custom username field");
              cb(event);
            }
          },
          {
            type: "password",
            label: "Password",
            placeholder: "",
            required: true,
            handleInputChange: (event, cb) => {
              console.log("custom username field");
              cb(event);
            }
          }
        ]}
        slot="sign-up"
      ></AmplifySignUp>

      {/*
  <AmplifyFormSection slot="sign-up">
    <AmplifyAuthFields
      formFields={[
        {
          type: "email",
          label: "Email Address2 *",
          required: true
        },
        {
          type: "password",
          label: "Password *",
          required: true
        }
      ]}
    />
    <div>rrrr</div>
    <div slot="amplify-form-section-footer">etrertter</div>
  </AmplifyFormSection>
    */}
      {props.children}
    </AmplifyAuthenticator>
  );
}

function AppHome() {
  return (
    <div>
      <JevulAuthenticator organization={"Jevul"}>
        <div>
          <UserContextProvider>
            <Router basename="/">
              {/* <Route
            render={route => {
              console.log(route);
            }}
          /> */}
              <div className="App">
                <Layout style={{ minHeight: "100vh" }}>
                  <Sider>
                    <div className="logo">Jevul</div>
                    <span className="heavy">
                      <Menu theme="dark" mode="inline" onClick={handleClick}>
                        <Menu.Item key="1">
                          <HomeOutlined />
                          <span>
                            <Link to="/">Dashboard</Link>
                          </span>
                        </Menu.Item>
                        {/*}
                    <Menu.Item key="2">
                      <Icon type="read" />
                      <span>Documentation</span>
                    </Menu.Item>
  
                    <Menu.Item key="3">
                      <Icon type="code" />
                      <span>Examples</span>
                    </Menu.Item>
                          */}
                        <Menu.Item key="4">
                          <LogoutOutlined />
                          <span>Logout</span>
                        </Menu.Item>
                      </Menu>
                    </span>
                  </Sider>
                  <Layout>
                    <Header
                      style={{ background: "#F0F2F5", margin: 10, height: 0 }}
                    />
                    <Content style={{ margin: "0 16px" }}>
                      <div
                        style={{
                          padding: 24,
                          background: "#fff",
                          minHeight: 360
                        }}
                      >
                        <Switch>
                          <Route exact path="/" component={Overview} />
                          <Route path="/appDetails" component={AppDetails} />
                          <Route path="/userList" component={UserList} />
                        </Switch>
                      </div>
                    </Content>
                    <Footer style={{ textAlign: "right" }}>Jevul ©2020</Footer>
                  </Layout>
                </Layout>
              </div>
            </Router>{" "}
          </UserContextProvider>
        </div>
      </JevulAuthenticator>
    </div>
  );
}

export default AppHome;
