import axios from "axios";

import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { Hub } from "aws-amplify";
import {
  ContactsTwoTone,
  AppstoreTwoTone,
  QuestionCircleOutlined
} from "@ant-design/icons";

import {
  Button,
  Input,
  Row,
  Col,
  Descriptions,
  Divider,
  Modal,
  Table,
  Form,
  Tooltip,
  Select
} from "antd";
import React, { useContext } from "react";
import "antd/dist/antd.css";
import "../App.css";
import { EventEmitter, loadCredentials, getToken } from "../Anon.js";

import { UserContext } from "../Contexts";
const { Option } = Select;

// Get the aws resources configuration parameters
//Amplify.configure(awsconfig);

function createApp(name, entity, callbackURL, email, token) {
  const Url = process.env.REACT_APP_API_URL + "users/createApp";
  EventEmitter.dispatch("appCreationAttempt", {});

  const options = {
    method: "POST",
    mode: "cors",
    headers: {
      Authorization: "Bearer " + token,
      "x-api-key": process.env.REACT_APP_API_KEY_VALUE,
      "Content-Type": "application/json"
    },
    url: Url,
    data: {
      poolName: name,
      entity: entity,
      callback: callbackURL,
      email: email
    }
  };

  axios(options)
    .then(function(value) {
      console.log("Create App:" + JSON.stringify(value));
      loadCredentials(token);
    })
    .catch(function(error) {
      console.log(error);
    })
    .finally(function() {});
}

Hub.listen("auth", data => {
  const { payload } = data;

  if (payload.event === "signIn") {
    getToken().then(
      function(token) {
        console.log("Success!", token);
        loadCredentials(token);
      },
      function(error) {
        console.error("Failed!", error);
      }
    );
  }
});

function Overview(props) {
  console.log("init Overview");

  getToken().then(
    function(token) {
      loadCredentials(token);
    },
    function(error) {
      console.error("Failed!", error);
    }
  );

  return (
    <div style={{ margin: 18 }}>
      <div>
        <div style={{ marginBottom: 18 }}>
          <Divider orientation="left">
            <ContactsTwoTone
              twoToneColor="#52c41a"
              style={{ marginRight: 15 }}
            />
            <span className="heavy">Your details</span>
          </Divider>
          <UserDetails />
        </div>

        <div style={{ marginTop: 50 }}>
          <Divider orientation="left">
            <AppstoreTwoTone
              twoToneColor="#52c41a"
              style={{ marginRight: 15 }}
            />
            <span className="heavy">Your security groups</span>
          </Divider>
        </div>

        <div style={{ textAlign: "left" }}>
          <p>
            Your existing security groups are listed here. Each represents a
            group of users, their personally identifiable data and any abritrary
            data you choose to store against their name. Each group provides you
            with a signup and login page, along with code snippets to query or
            edit their data.
          </p>

          <p>
            A security group effectively represents one app, or a suite of apps
            if you want to share logins and data across those apps.
          </p>
        </div>
        <AppView />
      </div>
    </div>
  );
}

var user_email = "";

function UserDetails(props) {
  //this.state = { name: "", email: "" };
  console.log("Init UserDetails");

  const { email, setEmail } = useContext(UserContext);
  const [name, setName] = useState("");

  useEffect(() => {
    const listener = details => {
      console.log("UserDetails, got details:" + JSON.stringify(details));
      setName(details["name"]);
      setEmail(details["email"]);
      user_email = details["email"];
    };

    EventEmitter.subscribe("userDetailsArrived", listener);

    return () => {
      EventEmitter.unsubscribe("userDetailsArrived", listener);
    };
  }, [setEmail]);

  return (
    <div className="UserDetails">
      <Descriptions column={1} bordered size="small">
        <Descriptions.Item label="Name">{name}</Descriptions.Item>
        <Descriptions.Item label="Email">{email}</Descriptions.Item>
      </Descriptions>
    </div>
  );
}

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    render: (text, record) => (
      <Link to={`/appDetails?id=${record.key}`}>{text}</Link>
    )
  },
  {
    dataIndex: "status",
    key: "status",
    render: tags => <span></span>
  }
];

function MyApps(props) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const appCreationAttempt = data => {
      setLoading(true);
    };

    const setDetails = details => {
      console.log("AppDetails, got details:" + JSON.stringify(details));

      var index;
      const data = [];

      for (index = 0; index < details.length; index++) {
        var appName = details[index]["Name"];
        var appID = details[index]["ID"];

        data.push({
          key: appID,
          name: appName,
          status: ""
        });
      }

      setLoading(false);
      setData(data);
    };
    EventEmitter.subscribe("appCreationAttempt", appCreationAttempt);
    EventEmitter.subscribe("appDetailsArrived", setDetails);

    return () => {
      EventEmitter.unsubscribe("appDetailsArrived", setDetails);
    };
  }, []);

  return (
    <div>
      <Table
        loading={loading}
        columns={columns}
        dataSource={data}
        size="small"
      />
    </div>
  );
}

function CreateAppForm() {
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();

  function doShow() {
    setVisible(true);
  }

  function doHide() {
    setVisible(false);
  }

  function onCancel() {
    console.log("Cancel");
    doHide();
  }

  function createGroup(values) {
    console.log("Create: " + JSON.stringify(values));

    getToken().then(
      function(token) {
        var callback = values.callback;

        console.log("Callback0=: ", callback);
        if (callback.length > 5) {
          //console.log("Callback1=: ", callback.substring(0, 4));

          if (callback.substring(0, 4).toLowerCase() === "http") {
            //console.log("Callback2=: ", callback);
          } else {
            //console.log("Callback2a=: ", callback);

            callback = protocol + callback;
          }
        } else {
          //console.log("Callback3=: ", callback);

          callback = protocol + callback;
        }

        //console.log("Callback=: ", callback);

        createApp(values.name, values.entity, callback, user_email, token);
        protocol = "https://";
      },
      function(error) {
        console.error("Failed!", error);
      }
    );

    form.resetFields();
    doHide();
  }

  return (
    <span>
      <Button type="primary" onClick={doShow}>
        Create Security Group
      </Button>

      <Modal
        visible={visible}
        title="Create a new security group"
        okText="Create"
        onCancel={() => {
          form.resetFields();
          onCancel();
        }}
        onOk={() => {
          form
            .validateFields()
            .then(values => {
              createGroup(values);
            })
            .catch(info => {
              console.log("Validate Failed:", info);
            });
        }}
      >
        <Form layout="vertical" form={form}>
          <Form.Item
            name="name"
            label="Public Name (e.g. name of your app)"
            rules={[
              {
                required: true,
                min: 3,
                message: "Group names must be greater than 3 characters long"
              }
            ]}
          >
            <Input placeholder="Name" />
          </Form.Item>
          <Form.Item
            name="entity"
            label="Company or website"
            rules={[
              {
                required: true,
                min: 3,
                message: "Company names must be greater than 3 characters long"
              }
            ]}
          >
            <Input placeholder="Company or website" />
          </Form.Item>
          <Form.Item
            name="callback"
            label={
              <span>
                Landing page after sign in&nbsp;
                <Tooltip title="This should be https. If you select http we shall wrap it in a redirect and invent ways of bugging you about it.">
                  <QuestionCircleOutlined twoToneColor="#52c41a" />
                </Tooltip>
              </span>
            }
            rules={[
              {
                required: true,
                message: "Please provide a callback URL for your app!"
              }
            ]}
          >
            <Input addonBefore={selectBefore} placeholder="your_url.com" />
          </Form.Item>
        </Form>
      </Modal>
    </span>
  );
}

function setProtocol(value) {
  console.log("select ", value);
  protocol = value;
}

var protocol = "https://";

const selectBefore = (
  <Select onChange={setProtocol} defaultValue="https://" style={{ width: 90 }}>
    <Option value="https://">https://</Option>
    <Option value="http://">http://</Option>
  </Select>
);

function AppView() {
  return (
    <div>
      <div style={{ margin: 18 }}>
        <Row type="flex" justify="start" align="top">
          <Col>
            <CreateAppForm />
          </Col>
        </Row>
      </div>

      <MyApps />
    </div>
  );
}
/*
class AppViewX extends React.Component {
  state = { visible: false };

  showModal = () => {
    console.log("poo");
    this.setState({
      visible: true
    });
  };

  handleCreate = () => {
    //console.log(e);

    const { form } = this.formRef.props;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      console.log("Received values of form: ", values);
      //console.log("Received values of form 1: ", JSON.stringify(selectBefore));

      getToken().then(
        function(token) {
          var callback = values.callback;

          console.log("Callback0=: ", callback);
          if (callback.length > 5) {
            //console.log("Callback1=: ", callback.substring(0, 4));

            if (callback.substring(0, 4).toLowerCase() === "http") {
              //console.log("Callback2=: ", callback);
            } else {
              //console.log("Callback2a=: ", callback);

              callback = protocol + callback;
            }
          } else {
            //console.log("Callback3=: ", callback);

            callback = protocol + callback;
          }

          //console.log("Callback=: ", callback);

          createApp(values.name, values.entity, callback, user_email, token);
          protocol = "https://";
        },
        function(error) {
          console.error("Failed!", error);
        }
      );

      form.resetFields();
      this.setState({ visible: false });
    });
  };

  handleCancel = e => {
    console.log(e);
    this.setState({
      visible: false
    });
  };

  saveFormRef = formRef => {
    this.formRef = formRef;
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    cancelAxios();
  }

  render() {
    return (
      <div>
        <div style={{ margin: 18 }}>
          <Row type="flex" justify="start" align="top">
            <Col>
              <Button type="primary" onClick={this.showModal}>
                Create Security Group
              </Button>
            </Col>
          </Row>
        </div>

        <CreateAppForm
          wrappedComponentRef={this.saveFormRef}
          visible={this.state.visible}
          onCancel={this.handleCancel}
          onCreate={this.handleCreate}
        />

        <MyApps />
      </div>
    );
  }
}*/

export default Overview;
