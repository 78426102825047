import "antd/dist/antd.css";
import "./App.css";
import AppHome from "./AppHome";

import React from "react";

function App() {
  return (
    <div>
      <AppHome class="App" />
    </div>
  );
}

export default App;
