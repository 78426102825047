import axios from "axios";
import Amplify, { Auth } from "aws-amplify";

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_AUTH_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
    mandatorySignIn: true
  }
});

export const EventEmitter = {
  events: {},
  dispatch: function(event, data) {
    console.log("Dispatch: " + event);
    if (!this.events[event]) return;
    this.events[event].forEach(callback => callback(data));
  },
  subscribe: function(event, callback) {
    console.log("Subscription: " + event);
    if (!this.events[event]) this.events[event] = [];
    this.events[event].push(callback);
  },
  unsubscribe: function(event, callback) {
    console.log("Unsubscribe: " + event);
    if (this.events[event]) {
      var index = this.events[event].indexOf(callback);
      if (index > -1) {
        this.events[event].splice(index, 1);
      }
    }
  }
};

export const Anon = {};

export async function getToken() {
  return await Auth.currentAuthenticatedUser()
    .then(user => {
      const token = user["signInUserSession"]["idToken"]["jwtToken"];
      console.log("Credentials:5: ", token);
      return token;
    })
    .catch(err => {
      console.log("getToken:", err);
      throw err;
    });
}

var source;

export function loadCredentials(token) {
  console.log("Token: " + token);

  const Url = process.env.REACT_APP_API_URL + "users/getUserDetails";
  var CancelToken = axios.CancelToken;

  source = CancelToken.source();
  const options = {
    method: "POST",
    mode: "cors",
    headers: {
      Authorization: "Bearer " + token,
      "x-api-key": process.env.REACT_APP_API_KEY_VALUE,
      "Content-Type": "application/json"
    },
    url: Url,
    data: {},
    cancelToken: source.token
  };

  console.log("loadCredentials:" + JSON.stringify(options));

  axios(options)
    .then(function(value) {
      console.log(JSON.stringify(value));
      var body = JSON.parse(JSON.stringify(value)).data; //.body);
      var userAtts = body["UserAttributes"];

      EventEmitter.dispatch("userDetailsArrived", userAtts);

      var apps = body["Apps"];
      EventEmitter.dispatch("appDetailsArrived", apps);
    })
    .catch(function(error) {
      console.log(error);
    })
    .finally(function() {});
}

export function cancelAxios() {
  source.cancel("Operation canceled by the user.");
}

export default Anon;
